import React from 'react';
import './savoir.css';
import { FaHtml5 } from 'react-icons/fa';
import { FaReact } from 'react-icons/fa';
import { FaCss3Alt } from 'react-icons/fa';
import { FaSass } from 'react-icons/fa';
import { FaGitAlt } from 'react-icons/fa';
import { DiJavascript } from 'react-icons/di';
import { DiVisualstudio } from 'react-icons/di';
import { SiAdobephotoshop } from 'react-icons/si';
import { SiPrestashop } from 'react-icons/si';
import { SiAdobeillustrator } from 'react-icons/si';
import { GrWordpress } from 'react-icons/gr';
import { GiSteamLocomotive } from 'react-icons/gi';
import imgGrid from '../../assets/imgs/grid-anim.svg';

const Savoir = () => {
      return (
            <>
                  <section className="savoirContainer" data-scroll-section>
                        <div className="savoirTitre">
                              <h2 className="blur" data-scroll data-scroll-speed="-3">
                                    Skillz
                              </h2>
                        </div>
                        <div className="savoirTitre">
                              <h2 className="nonBlur" data-scroll data-scroll-speed="4">
                                    Skillz
                              </h2>
                        </div>
                        <div className="savoirTitre">
                              <h2 className="blur2" data-scroll data-scroll-speed="-6">
                                    Skillz
                              </h2>
                        </div>
                        <img src={imgGrid} alt="grille" className="gridAnim" />
                  </section>
                  <section className="cardContainer" data-scroll-section>
                        <img src={imgGrid} alt="grille motifs 2" className="gridAnim" />
                        <div className="card" id="hoverEffect" data-scroll data-scroll-speed="6" data-scroll-direction="horizontal">
                              <div className="cardTitle">
                                    <FaHtml5 className="icon" />
                                    <h4>HTML</h4>
                              </div>
                              <div className="cardTitle">
                                    <FaCss3Alt className="icon" />
                                    <h4>CSS</h4>
                              </div>
                              <div className="cardTitle">
                                    <DiJavascript className="icon" />
                                    <h4>JAVASCRIPT</h4>
                              </div>
                        </div>
                        <div className="card" id="hoverEffect" data-scroll data-scroll-speed="-17" data-scroll-direction="horizontal">
                              <div className="cardTitle">
                                    <SiPrestashop className="icon" />
                                    <h4 className="psTitle">Prestashop</h4>
                              </div>
                              <div className="cardTitle">
                                    <GrWordpress className="icon" />
                                    <h4 className="mongoTitle">WORDPRESS</h4>
                              </div>
                        </div>
                        <div className="card" id="hoverEffect" data-scroll data-scroll-speed="10" data-scroll-direction="horizontal">
                              <div className="cardTitle">
                                    <FaSass className="icon" />
                                    <h4>SASS</h4>
                              </div>
                              <div className="cardTitle">
                                    <FaReact className="icon" />
                                    <h4>REACT</h4>
                              </div>
                              <div className="cardTitle">
                                    <GiSteamLocomotive className="icon" />
                                    <h4>LOCO SCROLL</h4>
                              </div>
                        </div>
                        <div className="card" id="hoverEffect" data-scroll data-scroll-speed="15" data-scroll-direction="horizontal">
                              <div className="cardTitle">
                                    <FaGitAlt className="icon" />
                                    <h4>GIT</h4>
                              </div>
                              <div className="cardTitle">
                                    <DiVisualstudio className="icon" />
                                    <h4 className="vsTitle">VScode</h4>
                              </div>
                        </div>
                        <div className="card" id="hoverEffect" data-scroll data-scroll-speed="-20" data-scroll-direction="horizontal">
                              <div className="cardTitle">
                                    <SiAdobephotoshop className="icon" />
                                    <h4 className="psTitle">Photoshop</h4>
                              </div>
                              <div className="cardTitle">
                                    <SiAdobeillustrator className="icon" />
                                    <h4 className="psTitle">Illustrator</h4>
                              </div>
                        </div>
                  </section>
            </>
      );
};

export default Savoir;
