import React, { useEffect } from 'react';
import Cursor from '../../components/cursor/Cursor';
import Header from '../../components/Home/Header';
import Info from '../../components/Home/Info';
import Savoir from '../../components/Home/Savoir';
import Plus from '../../components/Home/Plus';
import Footer from '../../components/Footer/Footer';
import Brutalism from '../../components/Home/Butalism/Brutalism';
import Nav from '../../components/nav/Nav';
import LocomotiveScroll from 'locomotive-scroll';
import Webgl from '../../components/Home/header/Webgl';

const Home = () => {
      const scrollRef1 = React.createRef();
      useEffect(() => {
            let scroll = new LocomotiveScroll({
                  el: scrollRef1.current,
                  smooth: true,
                  lerp: 0.05,
            });
      });

      return (
            <>
                  <Webgl />
                  <div className="topBanner">
                        <p>Site en construction</p>
                  </div>
                  <div ref={scrollRef1} data-scroll-container>
                        <Cursor />
                        <Nav />
                        <Header />
                        <Info />
                        <Savoir />
                        <Plus />
                        <Brutalism />
                        <Footer />
                  </div>
            </>
      );
};

export default Home;
