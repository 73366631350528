import React, { useEffect } from 'react';
import './matrixCard.css';
import github from '../../../assets/imgs/github.svg';

const MatrixCard = () => {
      useEffect(() => {
            const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            const randomChar = () => chars[Math.floor(Math.random() * (chars.length - 1))],
                  randomString = (length) => Array.from(Array(length)).map(randomChar).join('');

            const matrixCard = document.querySelector('.matrixCard');
            const letters = document.querySelector('.cardLetters');

            const mouseMoveEvent = (e) => {
                  const rect = matrixCard.getBoundingClientRect(),
                        x = e.clientX - rect.left,
                        y = e.clientY - rect.top;
                  letters.style.setProperty('--x', `${x}px`);
                  letters.style.setProperty('--y', `${y}px`);
                  letters.innerText = randomString(1500);
            };
            matrixCard.onmousemove = (e) => mouseMoveEvent(e);
      });
      return (
            <section className="matrixContainer" data-scroll-section>
                  <div className="matrixCard">
                        <div class="card-image">
                              <a href="https://github.com/valdruide" target="_blank" rel="noopener noreferrer">
                                    <img src={github} alt="logo github" />
                                    Github
                              </a>
                        </div>
                        <div className="card-gradient"></div>
                        <div className="cardLetters"></div>
                  </div>
            </section>
      );
};

export default MatrixCard;
