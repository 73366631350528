import React from 'react';
import Title from './Title';
import MatrixCard from './matrixCard/MatrixCard';

const Header = () => {
      return (
            <header id="header" data-scroll-section>
                  <div className="titre" id="titreContainerHeader">
                        <Title />
                        <h2 id="hoverEffect" data-scroll data-scroll-speed="3">
                              .Développeur Web <span className="neonText">Front-End</span>
                        </h2>
                  </div>
                  <MatrixCard />
            </header>
      );
};

export default Header;
