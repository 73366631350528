import React, { useEffect } from 'react';
import './loader.css';

const Loader = () => {
      useEffect(() => {
            const messageList = [
                  "Échange entre l'espace et le temps...",
                  'Pliage de la cuillère...',
                  'Péparation du café...',
                  'Test de votre patience...',
                  "Licenciement du stagiaire, nous allons avoir besoin d'un autre stagiaire...",
                  'Comptage des grains de sable sur Terre...',
                  "Construction de l'étoile noire...",
                  "Création d'un champ d'inversion de boucle temporelle...",
                  'Calcul des chances de succès...',
                  'Réglage du condensateur de flux...',
                  "Recherche de la réponse à la vie, l'univers et tout le reste...",
                  "S'assurer que tous les i ont des points...",
                  "Division de l'infini par 0...",
                  "Initialisation de l'initialiseur...",
                  "Optimisation de l'optimiseur...",
                  'Téléchargement du téléchargeur...',
                  'Génération du générateur...',
                  'Compilation du compileur...',
                  "Extraction de l'extracteur...",
                  "Mise à jour de l'outil de mise à jour...",
                  'Lecture des conditions générales pour vous...',
                  "Comptage à l'envers à partir de l'infini...",
                  "Vous êtes le numéro 2843684714 dans la file d'attente",
                  'Rupture de la barrière subspatiale, veuillez patienter...',
                  "Attente de l'arrivée du Tardis",
                  'Veuillez patienter pendant que le stagiaire remplit notre café...',
                  'Veuillez patienter pendant que nous convertissons le bug en fonctionnalité...',
                  'Veuillez patienter pendant que nous consultons le manuel...',
                  'Optimisation de la machine à café...',
                  'Chargement des messages amusants...',
                  'Localisation de Charly...',
                  'Recherche de nouveaux moyens pour vous faire attendre',
                  'Recherche du problème à la solution',
                  'Taper sur votre clavier ne fera pas charger la page plus vite !',
                  "Nettoyage des toiles d'araignées...",
                  "A l'AIDE! Je suis pris en otage et ils m'obligent à écrire ces lignes stupides!",
                  'Ajout de mots mal orthographiés au hasard dans le texte',
                  'Vous en avez assez des chargements lents? Achetez plus de RAM!',
                  'Attendez, je me suis perdu dans le cloud...',
                  'Optimisation de la vitesse de la lumière...',
                  'Patience, nous peignons les pixels un par un...',
                  'Enfilez vos chaussures de course, nous accélérons le chargement !',
                  'Notre équipe de stagiaires travaille dur pour accélérer le chargement. Soyez patient !',
                  "Vérification des archives de la Bibliothèque d'Alexandrie.",
                  'Nos robots de chargement sont en pause-café, ils reviennent bientôt !',
                  "Appel à l'aide des dieux de l'Olympe pour une vitesse divine.",
                  'Patience, nous sommes en train de battre un record de chargement olympique.',
                  "Négociation en cours avec le temps pour qu'il passe plus vite.",
                  "Attendez, nous préparons un feu d'artifice pour célébrer la fin du chargement.",
                  'Patience, nous faisons la danse de la pluie pour attirer les données plus rapidement.',
            ];
            let iteration = 0;
            const button = document.querySelector('.enterBtn');
            const skipBtn = document.querySelector('.skipBtn');
            const loader = document.getElementById('loader');
            skipBtn.addEventListener('click', () => skipLoader());
            const interval = setInterval(() => {
                  if (iteration === 6) {
                        clearInterval(interval);
                        const glow = document.querySelector('.progressGlow');
                        button.style.border = '1px solid #00f2e5';
                        button.style.color = '#fff';
                        button.addEventListener('click', () => hideLoader());
                        button.onmouseover = () => {
                              button.style.color = '#000';
                              button.style.backgroundColor = '#00f2e5';
                              button.style.fontWeight = 'bolder';
                        };
                        button.onmouseleave = () => {
                              button.style.color = '#fff';
                              button.style.backgroundColor = '#000';
                              button.style.fontWeight = 'normal';
                        };
                        skipBtn.style.transform = 'scale(0)';
                        glow.remove();
                        setTimeout(() => {
                              const messageContainer = document.getElementById('randomMessage');
                              const textMessage = document.createElement('p');
                              textMessage.innerText = '===[ Chargement terminé ! ]===';
                              messageContainer.appendChild(textMessage);
                              messageContainer.scrollTop = messageContainer.scrollHeight;
                        }, 500);
                  }

                  const messageContainer = document.getElementById('randomMessage');
                  const textMessage = document.createElement('p');
                  textMessage.innerText = messageList[Math.floor(Math.random() * messageList.length)];
                  messageContainer.appendChild(textMessage);
                  messageContainer.scrollTop = messageContainer.scrollHeight;
                  iteration += 1;
            }, 1200);

            const skipLoader = () => {
                  clearInterval(interval);
                  let cubes = document.getElementsByClassName('cubes');
                  let bienvenueTitle = document.getElementsByClassName('bienvenueTitle');
                  let chargementContainer = document.getElementsByClassName('chargementContainer');
                  chargementContainer[0].classList.add('chargementContainerRemove');
                  setTimeout(() => {
                        chargementContainer[0].remove();
                        cubes[0].remove();
                        bienvenueTitle[0].style.display = 'block';
                        bienvenueTitle[0].classList.add('bienvenueTitleAppear');
                  }, 500);
                  setTimeout(() => {
                        loader.classList.add('loaderRemove');
                  }, 1500);
                  setTimeout(() => {
                        loader.remove();
                  }, 2500);
            };
            const hideLoader = () => {
                  clearInterval(interval);
                  let cubes = document.getElementsByClassName('cubes');
                  let bienvenueTitle = document.getElementsByClassName('bienvenueTitle');
                  let chargementContainer = document.getElementsByClassName('chargementContainer');
                  chargementContainer[0].classList.add('chargementContainerRemove');
                  setTimeout(() => {
                        chargementContainer[0].remove();
                        cubes[0].remove();
                        bienvenueTitle[0].style.display = 'block';
                        bienvenueTitle[0].classList.add('bienvenueTitleAppear');
                  }, 500);
                  setTimeout(() => {
                        loader.classList.add('loaderRemove');
                  }, 1500);
                  setTimeout(() => {
                        loader.remove();
                  }, 2500);
            };

            const progressbar = document.querySelector('.progress');
            const progressText = document.querySelector('.progressText');
            const changeProgress = (progress) => {
                  progressbar.style.width = `${progress}%`;
                  progressText.innerText = `${progress}`;
            };
            /* change progress after 1 second (only for showcase) */
            setTimeout(() => changeProgress(12), 1000);
            setTimeout(() => changeProgress(22), 2500);
            setTimeout(() => changeProgress(45), 4000);
            setTimeout(() => changeProgress(56), 5000);
            setTimeout(() => changeProgress(85), 6600);
            setTimeout(() => changeProgress(98), 7866);
            setTimeout(() => changeProgress(100), 8300);
            return () => clearInterval(interval);
      });

      return (
            <div className="loader" id="loader">
                  <div className="cubes">
                        <div className="cube cube-1"></div>
                        <div className="cube cube-2"></div>
                        <div className="cube cube-3"></div>
                        <div className="cube cube-4"></div>
                        <div className="cube cube-5"></div>
                        <div className="cube cube-6"></div>
                        <div className="cube cube-7"></div>
                        <div className="cube cube-8"></div>
                        <div className="cube cube-9"></div>
                  </div>
                  <div className="chargementContainer">
                        <div className="chargementTextBox">
                              <p>CHARGEMENT</p>
                        </div>
                        <div id="randomMessage"></div>
                        <div className="btnContainer">
                              <div className="enterBtnDiv">
                                    <button className="enterBtn">ENTRER sur le site</button>
                              </div>
                              <div className="skipContainer">
                                    <button className="skipBtn">Passer le chargement</button>
                              </div>
                        </div>
                        <div className="progressDiv">
                              <div className="progress-container">
                                    <div className="progress"></div>
                                    <div className="progressGlow">
                                          <div className="glow"></div>
                                    </div>
                              </div>
                              <p className="progressText">00</p>
                        </div>
                  </div>
                  <p className="bienvenueTitle">BIENVENUE</p>
            </div>
      );
};

export default Loader;
