import React from 'react';
import './footer.css';
import { FaLinkedin } from 'react-icons/fa';
import { FaAt } from 'react-icons/fa';

const Footer = () => {
      return (
            <footer data-scroll-section>
                  <div className="footContainer" id="contact">
                        <div className="textContain">
                              <h2>ME CONTACTER</h2>
                              <div className="iconFoot">
                                    <a href="https://www.linkedin.com/in/tristan-viard/" target="_blank" rel="noreferrer">
                                          <FaLinkedin />
                                    </a>
                                    <a href="mailto:contact@tristan-viard.fr">
                                          <FaAt />
                                    </a>
                              </div>
                              <p className="phone">06 82 71 61 89</p>
                              <a href="mailto:contact@tristan-viard.fr" className="email">
                                    contact@tristan-viard.fr
                              </a>
                        </div>
                  </div>
            </footer>
      );
};

export default Footer;
