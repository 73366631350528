/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import './cursor.css';
import { useRef } from 'react';

const cursor = () => {
      const dot = useRef(null);
      const dot2 = useRef(null);

      const dot3 = useRef(null);

      const endX = useRef(window.innerWidth / 2);
      const endY = useRef(window.innerHeight / 2);

      useEffect(() => {
            const hoverEffect = document.querySelectorAll('#hoverEffect');
            document.addEventListener('mousemove', mouseMoveEvent);
            document.addEventListener('click', mouseClick);
            document.addEventListener('scroll', handleScroll);
            for (let i = 0; i < hoverEffect.length; i++) {
                  hoverEffect[i].addEventListener('mouseover', mouseHoverEvent);
                  hoverEffect[i].addEventListener('mouseout', mouseHoverOutEvent);
            }
      }, []);

      const mouseMoveEvent = (e) => {
            endX.current = e.pageX;
            endY.current = e.pageY;
            dot.current.style.top = endY.current + 'px';
            dot.current.style.left = endX.current + 'px';
            dot.current.style.transition = 'opacity .5s';
            dot.current.style.opacity = '1';

            dot3.current.style.top = endY.current + 'px';
            dot3.current.style.left = endX.current + 'px';
            dot3.current.style.transition = 'opacity .5s';
            dot3.current.style.opacity = '0';
      };

      const mouseHoverEvent = () => {
            dot3.current.classList.add('cursor2-Display');
            dot.current.classList.add('cursorHide');
      };

      const mouseHoverOutEvent = () => {
            dot3.current.classList.remove('cursor2-Display');
            dot.current.classList.remove('cursorHide');
      };

      const mouseClick = () => {
            dot2.current.classList.add('expand');
            setTimeout(() => {
                  dot2.current.classList.remove('expand');
            }, 500);
      };

      const handleScroll = (e) => {
            dot.current.style.opacity = '0';
            dot3.current.style.opacity = '0';
      };

      /* prettier-ignore */
      return (
            <>
                  <div ref={dot} id="cursor">
                        <span ref={dot2} className="inner-cursor"></span>
                  </div>
                  <div ref={dot3} id="cursor2">
                        <span className="inner-cursor2"></span>
                  </div>
            </>
      );
};

export default cursor;
