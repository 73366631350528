import Cursor from '../../components/cursor/Cursor';
import Nav from '../../components/nav/Nav';
import Footer from '../../components/Footer/Footer';
import ProjetHeader from '../../components/Projets/Header/ProjetHeader';
import LocomotiveScroll from 'locomotive-scroll';
import React, { useEffect } from 'react';
import ProjetBloc from '../../components/Projets/Blocs/ProjetBloc';
import Loader from '../../components/loader/Loader';
import Webgl from '../../components/Home/header/Webgl';

const Projets = () => {
      const scrollRef1 = React.createRef();
      useEffect(() => {
            let scroll = new LocomotiveScroll({
                  el: scrollRef1.current,
                  smooth: true,
                  lerp: 0.05,
            });
      });
      return (
            <>
                  <Webgl />
                  <div className="topBanner">
                        <p>Site en construction</p>
                  </div>
                  <div ref={scrollRef1} data-scroll-container>
                        <Loader />
                        <Cursor />
                        <Nav />
                        <ProjetHeader />
                        <ProjetBloc />
                        <Footer />
                  </div>
            </>
      );
};

export default Projets;
