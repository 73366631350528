import React, { useEffect } from 'react';
import './brutalism.css';
import gsap from 'gsap';
import { TweenMax } from 'gsap/gsap-core';
import { ExpoScaleEase, RoughEase, SlowMo } from 'gsap/EasePack';
import { Elastic } from 'gsap';

gsap.registerPlugin(TweenMax, ExpoScaleEase, RoughEase, SlowMo, Elastic);

const Brutalism = () => {
      useEffect(() => {
            var bt1 = document.querySelectorAll('#component-1')[0];
            var bt1c = document.querySelector('.button__container');
            var $circlesTopLeft = bt1.querySelectorAll('.circle.top-left');
            var $circlesBottomRight = bt1.querySelectorAll('.circle.bottom-right');

            var filter = document.querySelectorAll('#filter-goo-1 feGaussianBlur')[0];

            var tl = new gsap.timeline();
            var tl2 = new gsap.timeline();

            var btTl = new gsap.timeline({
                  paused: true,
                  onStart: function () {
                        filter.setAttribute('x', 0);
                  },
                  onComplete: function () {
                        bt1c.style.filter = 'none';
                  },
            });

            tl.to($circlesTopLeft, 1.2, { x: -25, y: -25, scaleY: 2, ease: SlowMo.ease.config(0.1, 0.7, false) });
            tl.to($circlesTopLeft[0], 0.1, { scale: 0.2, x: '+=6', y: '-=2' });
            tl.to($circlesTopLeft[1], 0.1, { scaleX: 1, scaleY: 0.8, x: '-=10', y: '-=7' }, '-=0.1');
            tl.to($circlesTopLeft[2], 0.1, { scale: 0.2, x: '-=15', y: '+=6' }, '-=0.1');
            tl.to($circlesTopLeft[0], 1, { scale: 0, x: '-=5', y: '-=15', opacity: 0 });
            tl.to($circlesTopLeft[1], 1, { scaleX: 0.4, scaleY: 0.4, x: '-=10', y: '-=10', opacity: 0 }, '-=1');
            tl.to($circlesTopLeft[2], 1, { scale: 0, x: '-=15', y: '+=5', opacity: 0 }, '-=1');

            var tlBt1 = new gsap.timeline();
            var tlBt2 = new gsap.timeline();

            tlBt1.set($circlesTopLeft, { x: 0, y: 0, rotation: -45 });
            tlBt1.add(tl);

            tl2.to($circlesBottomRight, 1.2, { x: 25, y: 25, scaleY: 2, ease: SlowMo.ease.config(0.1, 0.7, false) });
            tl2.to($circlesBottomRight[0], 0.1, { scale: 0.2, x: '-=6', y: '+=3' });
            tl2.to($circlesBottomRight[1], 0.1, { scale: 0.8, x: '+=7', y: '+=3' }, '-=0.1');
            tl2.to($circlesBottomRight[2], 0.1, { scale: 0.2, x: '+=15', y: '-=6' }, '-=0.1');
            tl2.to($circlesBottomRight[0], 1, { scale: 0, x: '+=5', y: '+=15', opacity: 0 });
            tl2.to($circlesBottomRight[1], 1, { scale: 0.4, x: '+=7', y: '+=7', opacity: 0 }, '-=1');
            tl2.to($circlesBottomRight[2], 1, { scale: 0, x: '+=15', y: '-=5', opacity: 0 }, '-=1');

            tlBt2.set($circlesBottomRight, { x: 0, y: 0, rotation: -45 });
            tlBt2.add(tl2);

            btTl.add(tlBt1);
            btTl.to(bt1.parentNode.querySelectorAll('.button__bg'), 0.8, { scaleY: 1.1 }, 0.1);
            btTl.add(tlBt2, 0.2);
            btTl.to(bt1.parentNode.querySelectorAll('.button__bg'), 1.8, { scale: 1, ease: Elastic.easeOut.config(1.2, 0.4) }, 1.2);

            btTl.timeScale(2.6);

            bt1.addEventListener('mouseover', function () {
                  bt1c.style.filter = 'url(#filter-goo-1)';
                  btTl.restart();
            });
      });
      return (
            <>
                  {/* <section className="brutalContainer" data-scroll-section>
                        <div className="txtG">
                              <h2 id="hoverEffect">
                                    PROJET<br></br>DESIGN
                              </h2>
                              <p id="hoverEffect" className="petiteInfo">
                                    Toujours à jour sur les nouvelles tendances en web design grâce à des veilles technologiques régulières sur différentes plateformes, ainsi que
                                    les artistes dont je suis le travail
                              </p>
                        </div>
                        <div className="txtD">
                              <h3 id="hoverEffect" className="error808">
                                    ERROR 808.
                              </h3>
                        </div>
                        <div className="botTxt">
                              <p id="hoverEffect" className="chinese">
                                    金属的脚镣危患
                              </p>
                              <p id="hoverEffect" className="botPoint">
                                    ●
                              </p>
                        </div>
                  </section> */}
                  <section className="brutalContainer2" data-scroll-section>
                        <div className="brutal1">
                              <h2 id="hoverEffect">
                                    <span>1.</span> MON TRAVAIL EN TANT QUE DÉVELOPPEUR WEB
                              </h2>
                              <h4 className="brutalSymbol">⧂</h4>
                              <p>
                                    En tant que développeur web front-end, je suis spécialisé dans la conception et le <strong>développement de sites web</strong> mais aussi d'
                                    <strong>applications web</strong>. Mon expertise inclut le <strong>web-design</strong>, <strong>l'expérience utilisateur (UX)</strong> et une
                                    créativité débordante qui rend les projets uniques.
                                    <br></br>
                                    <br></br>
                                    Je concois mes sites avec des outils tels que <strong>React</strong>, <strong>Locomotive scroll</strong>, <strong>SASS</strong> et bien d'autre
                                    en utilisant bien sur <strong>HTML, CSS et JavaScript</strong>, le tout en <strong>responsive</strong>, ce qui veut dire adapté à tous les
                                    supports nottament les <strong>mobiles</strong> pour les utilisateurs sur téléphone.
                                    <br></br>
                                    Je suis constamment à l'affût des dernières tendances et avancées technologiques dans le domaine du développement web, afin d'adapter mes
                                    compétences et mes connaissances aux besoins changeants du marché, ce qui est essentiel pour le <strong>référencement SEO</strong> et pour
                                    répondre aux attentes des utilisateurs.
                                    <br></br>
                                    <br></br>
                                    En utilisant ces connaissances, je suis capable de créer des expériences utilisateur interactives et fluides. Mon objectif est de créer des
                                    sites web performants, esthétiques et entièrement <strong>optimisés pour le référencement</strong>, afin de garantir une visibilité maximale sur
                                    les moteurs de recherche ainsi qu'une expérience unique et optimale pour les visiteurs du site.
                              </p>
                              <h2 id="hoverEffect" className="h2MarginTop">
                                    <span>2.</span> E-COMMERCE PRESTAHOP / WORDPRESS
                              </h2>
                              <p>
                                    Je sais faire du full code mais je maîtrise aussi les CMS <strong>Wordpress</strong> et <strong>Prestashop</strong>. De la création de site web
                                    <strong> e-commerce</strong> aux <strong>blogs</strong> en passant par les <strong>sites vitrines</strong>, ces deux CMS apportent une solution
                                    rapide, efficace et surtout maintenable pour une personne ou une entreprise ne maitrisant pas la programmation et ayant un budget réduit.
                              </p>
                              <h2 id="hoverEffect" className="h2MarginTop">
                                    <span>3.</span> EST-IL IMPORTANT D'AVOIR UN SITE WEB ?
                              </h2>
                              <p>
                                    Avoir un site web est devenu essentiel pour le développement d'une entreprise aujourd'hui. Cela lui permet de se connecter avec des clients
                                    potentiels dans le monde entier et de promouvoir ses produits et services en ligne afin de <strong>booster ses ventes</strong>
                                    <br></br>
                                    En choisissant de créer un <strong>site web professionnel</strong> et bien conçu, vous pouvez vous assurer que votre entreprise sera facilement
                                    accessible aux clients potentiels et vous pourrez renforcer votre marque et accroître la crédibilité de celle-ci.
                              </p>
                              <div className="cta_brutal">
                                    <div className="boxShadowAnim">
                                          <p id="hoverEffect">Ne cherchez plus ! Je suis prêt à vous aider à atteindre vos objectifs</p>
                                    </div>
                                    <div className="cta_btn">
                                          <a href="/projets">
                                                <button id="component-1" className="button button--1">
                                                      VOIR MES PROJETS
                                                      <span className="button__container">
                                                            <span className="circle top-left"></span>
                                                            <span className="circle top-left"></span>
                                                            <span className="circle top-left"></span>
                                                            <span className="button__bg"></span>
                                                            <span className="circle bottom-right"></span>
                                                            <span className="circle bottom-right"></span>
                                                            <span className="circle bottom-right"></span>
                                                      </span>
                                                </button>
                                          </a>
                                          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" className="svg-filters svg-bgtn-modifier">
                                                <defs>
                                                      <filter id="filter-goo-1">
                                                            <feGaussianBlur in="SourceGraphic" stdDeviation="7" result="blur" />
                                                            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                                                            <feComposite in="SourceGraphic" in2="goo" />
                                                      </filter>
                                                </defs>
                                          </svg>
                                    </div>
                              </div>
                        </div>
                  </section>
            </>
      );
};

export default Brutalism;
