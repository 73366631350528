import React from 'react';
import ReactDOM from 'react-dom/client';
import './Style/index.css';
import './Style/locoscroll.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
      <React.StrictMode>
            <App />
      </React.StrictMode>
);
