import React, { useEffect } from 'react';
import './card.css';
import $ from 'jquery';
import tristanImg2 from '../../../assets/imgs/carte/tristan2.png';

const Card = () => {
      useEffect(() => {
            const $cards = $('.cover-image');
            const $cardContainer = $('.mainCardContainer');
            let $style = $('.hover');
            $cards.on('mousemove', function (e) {
                  let $card = $(this);
                  let l = e.offsetX;
                  let t = e.offsetY;
                  let h = $card.height();
                  let w = $card.width();
                  let lp = Math.abs(Math.floor((100 / w) * l) - 100);
                  let tp = Math.abs(Math.floor((100 / h) * t) - 100);
                  let bg = `background-position: ${lp}% ${tp}%;`;
                  let style = `.cover-image.active:before { ${bg} }`;
                  $cards.removeClass('active');
                  $card.addClass('active');
                  $style.html(style);
            }).on('mouseout', function () {
                  $cards.removeClass('active');
            });

            const persoPng = document.querySelector('.character');
            const godrays = document.querySelector('.godrays');
            const card__wrapper = document.querySelector('.card__wrapper');
            $cardContainer.on('click', function () {
                  persoPng.style.display = 'none';
                  godrays.style.display = 'none';
                  card__wrapper.style.transform = 'none';
                  card__wrapper.style.setProperty('--Circle-overlay-background', 'transparent');
                  card__wrapper.classList.add('constantShadow');
            });
      });
      return (
            <>
                  <div className="mainCardContainer">
                        <div className="card__wrapper">
                              <div className="cover-image"></div>
                        </div>
                        <img src={tristanImg2} className="character" />
                        <div className="godrays"></div>
                  </div>
                  <style className="hover"></style>
            </>
      );
};

export default Card;
