import React, { useEffect } from 'react';
import './nav.css';
import { useState } from 'react';

const Nav = () => {
      useEffect(() => {
            document.querySelector('.navLink').onmouseover = (event) => {
                  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
                  let iterations = 0;
                  const interval = setInterval(() => {
                        event.target.innerText = event.target.innerText
                              .split('')
                              .map((letter, index) => {
                                    if (index < iterations) {
                                          return event.target.dataset.value[index];
                                    }
                                    return letters[Math.floor(Math.random() * 26)];
                              })
                              .join('');
                        if (iterations >= event.target.dataset.value.length) clearInterval(interval);
                        iterations += 1 / 3;
                  }, 30);
            };
            document.querySelector('.navLink2').onmouseover = (event) => {
                  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
                  let iterations = 0;
                  const interval = setInterval(() => {
                        event.target.innerText = event.target.innerText
                              .split('')
                              .map((letter, index) => {
                                    if (index < iterations) {
                                          return event.target.dataset.value[index];
                                    }
                                    return letters[Math.floor(Math.random() * 26)];
                              })
                              .join('');
                        if (iterations >= event.target.dataset.value.length) clearInterval(interval);
                        iterations += 1 / 3;
                  }, 10);
            };
            document.querySelector('.navLink3').onmouseover = (event) => {
                  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
                  let iterations = 0;
                  const interval = setInterval(() => {
                        event.target.innerText = event.target.innerText
                              .split('')
                              .map((letter, index) => {
                                    if (index < iterations) {
                                          return event.target.dataset.value[index];
                                    }
                                    return letters[Math.floor(Math.random() * 26)];
                              })
                              .join('');
                        if (iterations >= event.target.dataset.value.length) clearInterval(interval);
                        iterations += 1 / 3;
                  }, 20);
            };
            // document.querySelector('.navLink4').onmouseover = (event) => {
            //       const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            //       let iterations = 0;
            //       const interval = setInterval(() => {
            //             event.target.innerText = event.target.innerText
            //                   .split('')
            //                   .map((letter, index) => {
            //                         if (index < iterations) {
            //                               return event.target.dataset.value[index];
            //                         }
            //                         return letters[Math.floor(Math.random() * 26)];
            //                   })
            //                   .join('');
            //             if (iterations >= event.target.dataset.value.length) clearInterval(interval);
            //             iterations += 1 / 3;
            //       }, 30);
            // };
      });

      const [isOpen, setIsOpen] = useState(false);
      const navShow = () => {
            document.getElementById('navOpen').classList.remove('hide');
            document.getElementById('navOpen').classList.remove('hideAnim');
            document.getElementById('navOpen').classList.add('openAnim');
            document.getElementById('hamb').classList.add('open');
            setIsOpen(true);
      };

      const navHide = () => {
            document.getElementById('navOpen').classList.add('hideAnim');
            document.getElementById('hamb').classList.remove('openAnim');
            document.getElementById('hamb').classList.remove('open');
            setIsOpen(false);
      };

      return (
            <>
                  <div className="navContainer">
                        <div className="hamb" id="hamb" onClick={isOpen ? navHide : navShow}>
                              <div className="line"></div>
                              <div className="line"></div>
                              <div className="line"></div>
                        </div>
                  </div>
                  <div className="navOpen hide" id="navOpen">
                        <a href="/" data-value="Home" className="navLinkA navLink">
                              home
                        </a>
                        <a href="/projets" data-value="mes Projets" className="navLinkA navLink2">
                              mes projets
                        </a>
                        <a href="/about" data-value="A Propos" className="navLinkA navLink3">
                              a propos
                        </a>
                        {/* <a href="#contact" data-value="Contact" onClick={navHide} className="navLinkA navLink4">
                              contact
                        </a> */}
                  </div>
            </>
      );
};

export default Nav;
