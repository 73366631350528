import React, { useEffect } from 'react';
import './plus.css';
import $ from 'jquery';

import { gsap } from 'gsap';
import { ExpoScaleEase, RoughEase, SlowMo } from 'gsap/EasePack';
import { TextPlugin } from 'gsap/TextPlugin';

gsap.registerPlugin(TextPlugin, ExpoScaleEase, RoughEase, SlowMo);

const Plus = () => {
      useEffect(() => {
            let container = $('#demo'),
                  _sentenceEndExp = /(\.|\?|!)$/g; //regular expression to sense punctuation that indicates the end of a sentence so that we can adjust timing accordingly

            function machineGun(text) {
                  let words = text.split(' '),
                        tl = gsap.timeline({ delay: 0.6, repeat: -1, repeatDelay: 0 }),
                        wordCount = words.length,
                        time = 0,
                        word,
                        element,
                        duration,
                        isSentenceEnd,
                        i;

                  for (i = 0; i < wordCount; i++) {
                        word = words[i];
                        isSentenceEnd = _sentenceEndExp.test(word);
                        element = $('<h3>' + word + '</h3>').appendTo(container);
                        duration = Math.max(0.5, word.length * 0.08); //longer words take longer to read, so adjust timing. Minimum of 0.5 seconds.
                        if (isSentenceEnd) {
                              duration += 0.6; //if it's the last word in a sentence, drag out the timing a bit for a dramatic pause.
                        }
                        //set opacity and scale to 0 initially. We set z to 0.01 just to kick in 3D rendering in the browser which makes things render a bit more smoothly.
                        gsap.set(element, { autoAlpha: 0, scale: 0, z: 0.01 });
                        //the SlowMo ease is like an easeOutIn but it's configurable in terms of strength and how long the slope is linear. See https://www.greensock.com/v12/#slowmo and https://api.greensock.com/js/com/greensock/easing/SlowMo.html
                        tl.to(element, duration, { scale: 1.2, ease: 'slow(0.25, 0.9)' }, time)
                              //notice the 3rd parameter of the SlowMo config is true in the following tween - that causes it to yoyo, meaning opacity (autoAlpha) will go up to 1 during the tween, and then back down to 0 at the end.
                              .to(element, duration, { autoAlpha: 1, ease: 'slow(0.25, 0.9, true)' }, time);
                        time += duration - 0.05;
                        if (isSentenceEnd) {
                              time += 0.6; //at the end of a sentence, add a pause for dramatic effect.
                        }
                  }
            }
            machineGun('UI UX DESIGN SOCIAL VIDEO SEO.');
      });

      return (
            <>
                  <section className="plusContainer" data-scroll-section>
                        <div className="left_section"></div>
                        <div className="right_section">
                              <div className="plusTitre">
                                    <div className="lerpTitle">
                                          <h2 data-scroll data-scroll-speed="10" data-scroll-delay="0.20">
                                                L
                                          </h2>
                                          <h2 data-scroll data-scroll-speed="10" data-scroll-delay="0.18">
                                                e
                                          </h2>
                                          <h2 data-scroll data-scroll-speed="10" data-scroll-delay="0.14">
                                                s
                                          </h2>
                                          <h2 data-scroll data-scroll-speed="10" data-scroll-delay="0.13">
                                                .
                                          </h2>
                                          <h2 data-scroll data-scroll-speed="10" data-scroll-delay="0.12">
                                                p
                                          </h2>
                                          <h2 data-scroll data-scroll-speed="10" data-scroll-delay="0.09">
                                                l
                                          </h2>
                                          <h2 data-scroll data-scroll-speed="10" data-scroll-delay="0.06">
                                                u
                                          </h2>
                                          <h2 data-scroll data-scroll-speed="10" data-scroll-delay="0.04">
                                                s
                                          </h2>
                                    </div>
                              </div>
                              <div className="plusTxt">
                                    <div id="demo"></div>
                              </div>
                        </div>
                        <div className="decoTxt">
                              <p>
                                    言論及び信仰の自由が受けられ言論及び信仰の自由が受けられ言論及び信仰の自由が受けられ言論及び信仰の自由が受けられ言論及び信仰の自由が受けられ言論及び信仰の自由が受けられ言論及び信仰の自由が受けられ
                              </p>
                        </div>
                        <div className="defilement_txt_container">
                              <div className="defilement_txt">
                                    <p>
                                          はい<span>いいえ</span>こんにちは<span>じゃね</span>ありがとう<span>ありがとう</span>ごめんなさい<span>すみません</span>いえ
                                          <span>りょうしん</span>
                                    </p>
                              </div>
                              <div className="defilement_txt">
                                    <p>
                                          はい<span>いいえ</span>こんにちは<span>じゃね</span>ありがとう<span>ありがとう</span>ごめんなさい<span>すみません</span>いえ
                                          <span>りょうしん</span>
                                    </p>
                              </div>
                        </div>
                  </section>
            </>
      );
};

export default Plus;
