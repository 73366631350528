import React from 'react';
import './aboutme.css';
import Card from './card/Card';
import scrollIcon from '../../assets/imgs/scrollDown1.svg';

const Aboutme = () => {
      return (
            <div className="aboutMeContainer" id="about" data-scroll-section>
                  <div className="contentContainer">
                        <div className="aboutR">
                              <Card />
                              <img src={scrollIcon} alt="icon scroll" className="scrollDownImg" />
                        </div>
                  </div>
                  <div className="aboutG">
                        <div className="aboutTextTitle">
                              <h1 id="hoverEffect">
                                    À Propos de moi,<br></br>l'univers<br></br> et tout le reste
                              </h1>
                        </div>
                        <div className="aboutText">
                              <p>
                                    Bien le bonjour, je suis Tristan, 23 ans à l'heure où j'écris ces lignes, développeur et intégrateur web sur Albi.
                                    <br></br>
                                    <br></br>
                                    Ancien forgeron d'art à Saint Tropez, c'est suite à un désir de faire de ma passion mon métier que je me suis reconverti dans le développement
                                    web, un domaine qui me passionne depuis mes 12 ans.
                                    <br></br>
                                    <br></br>
                                    Développement de scripts en <strong>JS et PHP</strong>, création de sites web en full code avec <strong>React</strong> ou sur{' '}
                                    <strong>CMS (WordPress / Prestashop)</strong> ou bien intégration d'une maquette de votre projet, il me tarde de travailler avec vous !<br></br>
                                    <br></br>
                                    Ayant un penchant plus que prononcé sur toute la partie graphique, animation et design d'un site web, je saurai vous proposer un travail de
                                    haute qualité, unique et surtout graphique pour vos projets en y ajoutant tout ce qui se fait de mieux en terme de tendance web tout en
                                    respectant les règles <strong>SEO</strong> afin de vous proposer un référencement optimal.
                                    <br></br>
                                    Je peux aussi travailler sur du back-end avec de la <strong>création d'API</strong> ainsi que de la{' '}
                                    <strong>gestion et création de base de données</strong>.<br></br>
                                    <br></br>
                                    Quant à la réponse à l'univers et tout le reste : 42
                                    <br></br>
                                    <br></br>
                                    <strong>CETTE PAGE EST ENCORE EN CONSTRUCTION</strong>
                              </p>
                        </div>
                  </div>
            </div>
      );
};

export default Aboutme;
