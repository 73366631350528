import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Projets from './pages/Projets/Projets';
import Error from './pages/error/Error';
import About from './pages/About/About';
const App = () => {
      return (
            <BrowserRouter>
                  <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/projets" element={<Projets />} />
                        <Route exact path="/about" element={<About />} />
                        <Route exact path="*" element={<Error />} />
                  </Routes>
            </BrowserRouter>
      );
};

export default App;
