/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import './projetBloc.css';

const ProjetBloc = () => {
      return (
            <section data-scroll-section>
                  <div className="blocBaniere">
                        <h2>Créations</h2>
                  </div>
                  <div className="projetContainer ">
                        <div className="projetColG borderRight">
                              <div className="shiftclicImg projetImg"></div>
                        </div>
                        <div className="projetColD">
                              <div className="projetTxt">
                                    <h3>━━━ Shiftclic</h3>
                                    <p>
                                          Shiftclic est l'entreprise dans laquelle j'ai effectué mon alternance en développement web. Le site a été réalisé une première fois en
                                          full code avec Bootstrap et JS natif. Mais pour des questions de maintiens de la page "projets" qui devait être régulièrement mise à jour,
                                          j'ai dû refaire le site sur le célèbre CMS wordpress avec du custom CSS et Elementor.<br></br>
                                          <br></br>
                                          Ce site se veut très graphique afin de présenter au mieux notre travail en développement mais aussi en web design.
                                    </p>
                                    <a href="https://shiftclic.fr" target="_blank" className="siteLink">
                                          <div className="exempleLink">Voir le site</div>
                                    </a>
                              </div>
                        </div>
                  </div>
                  <div className="projetContainer ">
                        <div className="projetColG borderRight">
                              <div className="projetTxt">
                                    <h3>Tellus Sensors ━━━</h3>
                                    <p>
                                          Tellus Sensors est une entreprise américaine qui commercialise des capteurs d’air. Les données de ces capteurs sont retransmises sur une
                                          page utilisateur dédiée au client via leur API. Ainsi, le client obtient les informations telles que le taux d’humidité, la température et
                                          bien plus.
                                          <br></br>
                                          <br></br>
                                          Pour une entreprise comme celle-ci, il fallait un site très professionnel, sans fioritures avec un design moderne.
                                          <br></br>
                                          <br></br>
                                          <span>
                                                PS: Beaucoup de textes et de mise en page ont été changés sur le site par les clients, ce qui rend celui-ci un peu moins beau car
                                                moins ordonné. <br></br>
                                                Ce n'est donc pas représentatif de mon travail !
                                          </span>
                                    </p>
                                    <a href="https://www.tellusensors.com" target="_Blank" className="siteLink">
                                          <div className="exempleLink">Voir le site</div>
                                    </a>
                              </div>
                        </div>
                        <div className="projetColD">
                              <div className="tellusImg projetImg"></div>
                        </div>
                  </div>
            </section>
      );
};

export default ProjetBloc;
