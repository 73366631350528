import React from 'react';
import './info.css';
import hand from '../../assets/imgs/hand.webp';

const Info = () => {
      return (
            <section className="infoSection" id="infoSection" data-scroll-section>
                  <img src={hand} alt="main" className="handImg" data-scroll data-scroll-speed="-3" data-scroll-direction="horizontal" />
                  <div className="txt" data-scroll data-scroll-speed="-4" data-scroll-direction="horizontal">
                        <h2 id="hoverEffect">
                              Transformer des idées
                              <br /> en projets
                              <br /> est ma vocation
                        </h2>
                  </div>
            </section>
      );
};

export default Info;
