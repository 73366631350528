import Cursor from '../../components/cursor/Cursor';
import React from 'react';
import Nav from '../../components/nav/Nav';
import Footer from '../../components/Footer/Footer';

const Error = () => {
      return (
            <>
                  <div className="topBanner">
                        <p>Site en construction</p>
                  </div>
                  <Cursor />
                  <Nav />
                  <div className="errorPage">
                        <h1>404</h1>
                        <h2>ERROR</h2>
                  </div>
                  <Footer />
            </>
      );
};

export default Error;
