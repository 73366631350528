import React from 'react';
import './projetHeader.css';
import { MdInfoOutline } from 'react-icons/md';

const ProjetHeader = () => {
      return (
            <section className="projetHeaderContainer" data-scroll-section>
                  <div className="headColG">
                        <h1>PROJETS EN VRAC</h1>
                        <p>
                              Cette page répertorie quelques projets réalisés en solo et/ou en milieu professionnel.<br></br>Vous y trouverez les projets que j'ai jugé les plus
                              importants et les plus représentatifs de mon travail.
                        </p>
                  </div>
                  <div className="headColD"></div>
                  <div className="headColG2">
                        <MdInfoOutline className="infoHeaderIcon" />
                        <p>Cette page est en construction</p>
                  </div>
                  <div className="headColD2"></div>
            </section>
      );
};

export default ProjetHeader;
