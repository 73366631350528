import React from 'react';
import './title.css';

const Title = () => {
      return (
            <div>
                  <h1 className="title" id="hoverEffect" data-scroll data-scroll-speed="3">
                        TRISTAN VIARD
                  </h1>
            </div>
      );
};

export default Title;
